// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Router, Location } from '@reach/router';
import { ThemeProvider } from 'styled-components';
import Helmet from 'react-helmet';
import Amplify from 'aws-amplify';

import { SnackbarProvider } from 'notistack';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Navbar } from '../navigation';
import { theme, GlobalStyle } from '../../styles';
import { ModalProvider } from '../../components/modules';
import { AuthProvider } from '../auth';
import { Box } from '../../components/elements';
import { Home } from '../home';

import Config from '../../config';

Amplify.configure(Config.cognitoSettings);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme.muiTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AuthProvider>
            <SnackbarProvider maxSnack={3}>
              <Helmet>
                <link
                  href="https://fonts.googleapis.com/css?family=Nova+Square&display=swap"
                  rel="stylesheet"
                />
                <link
                  href="https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap"
                  rel="stylesheet"
                />
                <link
                  href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap"
                  rel="stylesheet"
                />
              </Helmet>
              <ModalProvider>
                <Home path="/" />
              </ModalProvider>
            </SnackbarProvider>
          </AuthProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

export default hot(App);
