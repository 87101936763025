import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { CreateAccount } from './create-account';
import { Login } from './login';
import { ConfirmAccount } from './confirm-account';
import { ForgotPassword } from './forgot-password';
import { ResetPassword } from './reset-password';
import ContentModal from '../../components/elements/ContentModal';

const AuthModal = ({ view, isOpen, onClose }) => {
  const [currentView, setCurrentView] = useState(view ?? 'login');
  const modalViews = {
    login: Login,
    'create-account': CreateAccount,
    'forgot-password': ForgotPassword,
    'confirm-account': ConfirmAccount,
    'reset-password': ResetPassword
  };
  const AuthComponent = modalViews[currentView] ?? modalViews.login;

  useEffect(() => {
    if (isOpen) {
      setCurrentView(view ?? 'login');
    }
  }, [isOpen]);

  return (
    <ContentModal
      isOpen={isOpen}
      modalStyle={{ maxWidth: '435px', width: '100%' }}
      onClose={onClose}
    >
      <AuthComponent
        changeView={nextView => setCurrentView(nextView)}
        closeModal={onClose}
      />
    </ContentModal>
  );
};

AuthModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  view: PropTypes.string
};

export default AuthModal;
