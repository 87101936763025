/* eslint-disable no-use-before-define */
import React, {
  useState,
  useRef,
  useMemo,
  createContext,
  useContext,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { IoIosClose } from 'react-icons/io';
import { Box, Text, Button } from '../elements';

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const modalNode = useRef();

  const value = modalNode;

  return (
    <ModalContext.Provider value={value}>
      {children}
      <div ref={modalNode} id="modal" />
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired
};

function useModal() {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const MemoModal = useMemo(
    () =>
      function Modal({
        bgProps,
        children,
        containerProps,
        onBgClick,
        onEscapeKey,
        closeBtnCb
      }) {
        const bgRef = useRef();
        const modalNode = useContext(ModalContext);

        if (!modalNode) {
          throw new Error(`useModal must be used within a ModalProvider`);
        }

        useEffect(() => {
          if (isOpen) {
            document.addEventListener('keydown', handleKeydown);
            document.body.style.overflow = 'hidden';
            const container = document.querySelector('#app-container');
            if (container) {
              container.style.filter = 'blur(8px)';
            }
          }

          return () => {
            document.removeEventListener('keydown', handleKeydown);
            document.body.style.overflow = 'auto';
            const container = document.querySelector('#app-container');
            if (container) {
              container.style.filter = 'none';
            }
          };
        }, [isOpen]);

        const handleBackgroundClick = e => {
          if (onBgClick && bgRef.current && bgRef.current === e.target) {
            e.stopPropagation();
            onBgClick();
          }
        };

        const handleKeydown = e => {
          if (e.key === 'Escape' && onEscapeKey) {
            onEscapeKey();
          }
        };

        return (
          isOpen &&
          ReactDOM.createPortal(
            <Box
              ref={bgRef}
              alignItems={['flex-end', 'center']}
              bg="overlay"
              height="100vh"
              justifyContent="center"
              left="0"
              onMouseDown={handleBackgroundClick}
              overflow="auto"
              position="fixed"
              px={[0, 2]}
              top="0"
              width="100vw"
              zIndex="modal"
              {...bgProps}
            >
              <Box
                ref={containerRef}
                alignItems="stretch"
                bg="modalBg"
                borderRadius={2}
                color="greys.7"
                flexDirection="column"
                height={['100%', 'auto']}
                maxHeight={['100%', '90vh']}
                overflow="auto"
                position="relative"
                role="dialog"
                tabIndex="-1"
                width={['100%', '100%', 1]}
                {...containerProps}
              >
                {closeBtnCb && (
                  <Button
                    alignSelf="flex-end"
                    ariaLabel="Close modal"
                    color="white"
                    fontWeight="bold"
                    id="modalCloseButton"
                    onClick={closeBtnCb}
                    p={2}
                    position="absolute"
                    right="0.5rem"
                    top="0.875rem"
                    variant="tertiary"
                    zIndex={1}
                  >
                    <Text
                      alignItems="center"
                      as="span"
                      color="black"
                      display="flex"
                      fontWeight={2}
                    >
                      <IoIosClose size="1.875rem" />
                    </Text>
                  </Button>
                )}
                {children}
              </Box>
            </Box>,
            modalNode.current
          )
        );
      },
    [isOpen]
  );

  return {
    openModal,
    closeModal,
    isOpen,
    Modal: MemoModal,
    containerRef
  };
}

export { ModalProvider, useModal };
