import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Divider, Image } from 'semantic-ui-react';
import { Box, Input, Text } from '../../components/elements';
import { Message, Loading } from '../../components/compound';
import { useAuthService } from './auth.provider';
import logo from '../../images/sportsheads-logo.png';

const Login = ({ changeView, closeModal, fullScreen, navigate }) => {
  const {
    onReset,
    email,
    onLogin,
    error,
    clearErrors,
    loading,
    isWaitingConfirmSignUp,
    isAuthenticated
  } = useAuthService();
  const emailRef = useRef();
  const [hasAttemptedLogin, setHasAttemptedLogin] = useState(false);
  const [formState, setFormState] = useState({
    email,
    password: ''
  });

  useEffect(() => {
    if (!email || isWaitingConfirmSignUp) {
      onReset();
    }

    setFormState({
      email,
      password: ''
    });
    if (emailRef && emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (hasAttemptedLogin && email && isWaitingConfirmSignUp) {
      if (fullScreen) {
        navigate('/confirm');
      } else {
        changeView('confirm-account');
      }
    }
  }, [isWaitingConfirmSignUp]);

  useEffect(() => {
    if (isAuthenticated) {
      if (fullScreen) {
        onLogin(formState, () => navigate('/'));
      } else {
        onLogin(formState, closeModal);
      }
    }
  }, [isAuthenticated]);

  const handleChange = e => {
    const { value, name } = e.target;
    setFormState({
      ...formState,
      [name]: value
    });
  };

  const viewSwitch = view => {
    clearErrors();
    if (fullScreen) {
      navigate(`/${view}`);
    } else {
      changeView(view);
    }
  };

  return (
    <Box
      flexDirection="column"
      maxWidth="435px"
      style={{
        padding: '24px 16px',
        margin: fullScreen ? '1.25rem auto' : null,
        borderRadius: fullScreen ? 10 : null,
        backgroundColor: fullScreen ? '#FFF' : null,
        boxShadow: fullScreen ? 2 : 0
      }}
      width="100%"
    >
      <Box justifyContent="center">
        <Image
          bordered
          circular
          css={{ marginBottom: 25 }}
          size="tiny"
          src={logo}
        />
      </Box>

      <Box
        as="form"
        flexDirection="column"
        onSubmit={e => {
          e.preventDefault();
          setHasAttemptedLogin(true);
          if (fullScreen) {
            onLogin(formState, () => navigate('/'));
          } else {
            onLogin(formState, closeModal);
          }
        }}
      >
        <Input
          ref={emailRef}
          containerProps={{
            width: '100%',
            height: '40px',
            alignItems: 'start'
          }}
          name="email"
          onChange={handleChange}
          placeholder="Email"
          required
          style={{ borderRadius: '4px' }}
          value={formState.email ?? ''}
          variant="register"
        />
        <Input
          containerProps={{
            mt: 4,
            width: '100%',
            height: '40px',
            alignItems: 'start'
          }}
          name="password"
          onChange={handleChange}
          placeholder="Password"
          required
          style={{ borderRadius: '4px' }}
          type="password"
          value={formState.password ?? ''}
          variant="register"
        />

        {loading ? (
          <Box style={{ justifyContent: 'center', marginTop: 20 }}>
            <Loading dotBackgroundColor="black" />
          </Box>
        ) : (
          <Box
            className="testbox"
            flexDirection="column"
            justifyContent="flex-start"
          >
            <Button
              size="small"
              style={{
                color: 'white',
                backgroundColor: '#F3C20A',
                flex: 1,
                marginTop: '1.25rem',
                textTransform: 'none'
              }}
              type="submit"
            >
              Log In
            </Button>
            <Button
              color="primary"
              onClick={() => viewSwitch('forgot-password')}
              style={{
                marginLeft: '0.625rem',
                fontWeight: 'bold',
                marginTop: 12,
                fontSize: '0.85rem',
                textTransform: 'none'
              }}
              type="button"
            >
              Forgot Password?
            </Button>
          </Box>
        )}
      </Box>

      {error && (
        <Message style={{ marginTop: 10 }} variant="error">
          {error}
        </Message>
      )}
      <Divider
        horizontal
        style={{ marginTop: 20, marginBottom: 30, width: '100%' }}
      >
        <Text color="#606770" fontSize="0.85rem" fontWeight="normal">
          OR
        </Text>
      </Divider>

      <Box justifyContent="center" style={{ marginBottom: 10 }}>
        <Button
          color="secondary"
          onClick={() => viewSwitch('create-account')}
          size="small"
          style={{
            backgroundColor: '#A7C957',
            color: 'white',
            marginLeft: 40,
            marginRight: 40,
            flex: 1,
            textTransform: 'none'
          }}
          type="button"
        >
          Create new account
        </Button>
      </Box>
    </Box>
  );
};

Login.propTypes = {
  changeView: PropTypes.func,
  closeModal: PropTypes.func,
  fullScreen: PropTypes.bool,
  navigate: PropTypes.func
};

export { Login };
