import { error } from './logging';

export const LS_KEYS = {
  email: 'email',
  user_uuid: 'user_uuid'
};

const getItem = key => {
  const item = localStorage.getItem(key);
  try {
    return JSON.parse(item);
  } catch (e) {
    error('Error', e);
    return null;
  }
};

const setItem = (key, data) => {
  if (!data) {
    error('You gave local storage nothing!');
    return;
  }

  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    error('Error setting', e);
  }
};

const removeItem = key => localStorage.removeItem(key);

export default { getItem, setItem, removeItem };
