import React, { useEffect } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as ReachLink, navigate, useLocation } from '@reach/router';
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Switch
} from '@material-ui/core';
import BuildIcon from '@material-ui/icons/BuildOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToAppOutlined';
import { Box, Link, Text } from '../../components/elements';
import { links } from './links';
import { useAuthService } from '../auth';
import { theme } from '../../styles';
import { getInitialsFromName } from '../../utils/helpers';

const propTypes = {};

const defaultProps = {
  containerProps: {}
};

const MobileMenu = () => {
  return <></>;
};

MobileMenu.propTypes = propTypes;
MobileMenu.defaultProps = defaultProps;

export { MobileMenu };
