/* eslint-disable consistent-return */
/* eslint-disable no-console */
import _ from 'lodash';
import React, { useReducer, useMemo, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { fetcher } from '../../network/fetcher';
import LS, { LS_KEYS } from '../../utils/local-storage';
import Config from '../../config';
import { debug, error } from '../../utils/logging';
import { LengthRequirement } from '../../utils/password';

const AuthContext = React.createContext();

const initialState = {
  error: null,
  avatar: null,
  email: LS.getItem(LS_KEYS.email),
  uuid: LS.getItem(LS_KEYS.user_uuid),
  password: null,
  firstName: null,
  lastName: null,
  loading: false,
  isAdmin: false,
  pkUser: 0,
  isConfirmed: false,
  isAuthenticated: false,
  isWaitingConfirmSignUp: false,
  isSubscribed: false,
  styleReference: document.querySelector('style[data-styled]'),
  passwordResetSuccessful: false,
  stripeCustomerId: null,
  teams: [],
  isDarkMode: localStorage?.getItem('darkMode') !== 'false'
};

const emptyState = {
  error: null,
  avatar: null,
  email: null,
  uuid: null,
  password: null,
  firstName: null,
  lastName: null,
  loading: false,
  isAdmin: false,
  isConfirmed: false,
  isAuthenticated: false,
  isWaitingConfirmSignUp: false,
  isSubscribed: false,
  styleReference: document.querySelector('style[data-styled]'),
  passwordResetSuccessful: false,
  stripeCustomerId: null,
  teams: []
};

const getError = obj => {
  return 'ERROR';
};

const reducer = (state, action) => {
  return initialState;
};

function AuthProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const isAdmin = user => {};

  const setAuthCookie = async () => {};

  const checkStillSignedIn = async () => {};

  useEffect(() => {
    checkStillSignedIn();
  }, []);

  const onLogin = async (payload, callback) => {};

  const onResendVerificationCode = async (payload, callback) => {};

  const onForgotPassword = async (payload, callback) => {};

  const onResetPassword = async payload => {};

  const onCreateAccount = async payload => {};

  const onConfirmAccount = async payload => {};

  const clearErrors = () => {};

  const onReset = () => {};

  const onLogout = async () => {};

  const changePassword = async payload => {};

  const onCheckExistingUser = async (payload, callback) => {};

  const onToggleDarkMode = () => {};

  const value = useMemo(
    () => ({
      ...state,
      onReset,
      onLogin,
      onConfirmAccount,
      onResendVerificationCode,
      onForgotPassword,
      onResetPassword,
      onCreateAccount,
      onCheckExistingUser,
      changePassword,
      clearErrors,
      onLogout,
      checkStillSignedIn,
      onToggleDarkMode
    }),
    // eslint-disable-next-line prettier/prettier
    // eslint-disable-next-line comma-dangle
    [state]
  );
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <AuthContext.Provider value={value} {...props} />;
}

function useAuthService() {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error(`useAuthService must be used within an AuthProvider`);
  }

  return context;
}

export { AuthProvider, useAuthService };
