import React, { useState, useEffect, useRef } from 'react';
import { Link as ReachLink, navigate, useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import {
  Avatar,
  Icon,
  useMediaQuery,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Divider
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import BuildIcon from '@material-ui/icons/BuildOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToAppOutlined';
import { withStyles } from '@material-ui/styles';
import PersonIcon from '@material-ui/icons/Person';
import { Box, Text, Link } from '../../components/elements';
import { MobileMenu } from './mobile-menu';
import { links } from './links';
import logo from '../../images/sportsheadz.png';
import { useAuthService } from '../auth';
import { getInitialsFromName } from '../../utils/helpers';
import { theme } from '../../styles';
import AuthModal from '../auth/AuthModal';

const propTypes = {
  pathname: PropTypes.string.isRequired
};

const defaultProps = {};

const Navbar = ({ pathname }) => {
  return <></>;
};

Navbar.propTypes = propTypes;
Navbar.defaultProps = defaultProps;

export { Navbar };
