import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// import favicon32 from '../images/favicon32.png'

const propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  keywords: PropTypes.array,
  lang: PropTypes.string,
  meta: PropTypes.array,
  type: PropTypes.string
};

const defaultProps = {
  keywords: [],
  lang: '',
  meta: [],
  type: 'website'
};

const SEO = ({
  description,
  lang = 'en',
  meta = [],
  keywords = [],
  title,
  type = 'website'
}) => (
  <Helmet
    htmlAttributes={{
      lang
    }}
    link={
      [
        /* { rel: 'shortcut icon', type: 'image/png', href: `${favicon32}` } */
      ]
    }
    meta={[
      {
        name: `description`,
        content: description
      },
      {
        property: `og:title`,
        content: title
      },
      {
        property: `og:description`,
        content: description
      },
      {
        property: `og:type`,
        content: type
      }
      // For Twitter
      // {
      //   name: `twitter:card`,
      //   content: `summary`,
      // },
      // {
      //   name: `twitter:creator`,
      //   content: site.siteMetadata.author,
      // },
      // {
      //   name: `twitter:title`,
      //   content: title,
      // },
      // {
      //   name: `twitter:description`,
      //   content: metaDescription,
      // },
    ]
      .concat(
        keywords.length > 0
          ? {
              name: `keywords`,
              content: keywords.join(`, `)
            }
          : []
      )
      .concat(meta)}
    title={title}
    titleTemplate="SportsHeadz"
  />
);

SEO.propTypes = propTypes;
SEO.defaultProps = defaultProps;

export { SEO };
