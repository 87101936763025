/* eslint-disable no-console */
export default class Config {
  static get apiServerHost() {
    let url = '';
    if (process.env.NODE_ENV === 'production') {
      url = `https://api.sportsheadz.com/prod/`;
    }
    if (process.env.NODE_ENV === 'development') {
      url = `https://api.sportsheadz.com/stage/`;
    }
    if (process.env.NODE_ENV === 'none') {
      url = `http://localhost:61249/`;
    }
    if (window.location.hostname === 'dev.broadcast.sportsheadz.com') {
      url = `https://api.sportsheadz.com/stage/`;
    }

    return url;
  }

  static get enableConsoleLogging() {
    return process.env.NODE_ENV !== 'production';
  }

  static get cognitoSettings() {
    const settingsProd = {
      Auth: {
        identityPoolId: 'us-east-1:93ca516d-e2c4-4ad6-b44c-4b05604c8bd7',
        region: 'us-east-1',
        userPoolId: 'us-east-1_FAoqNjX2j',
        userPoolWebClientId: '4fdno1j1g5jukptomkdifu3gao',
        mandatorySignIn: true
      },
      Storage: {
        bucket: 'hockeyheadz-storage',
        region: 'us-east-1'
      }
    };

    const settingsDev = {
      Auth: {
        identityPoolId: 'us-east-1:5111a894-2c2c-41b1-a29a-f717eaa9aa31',
        region: 'us-east-1',
        userPoolId: 'us-east-1_Z2PFfqRF1',
        userPoolWebClientId: '624bs7emni9leiuspgcrrs9j8s',
        mandatorySignIn: true
      },
      Storage: {
        bucket: 'hockeyheadz-storage',
        region: 'us-east-1'
      }
    };

    if (window.location.hostname === 'dev.broadcast.sportsheadz.com') {
      // Todo: change this for a dev version of auth
      return settingsDev;
    }

    if (process.env.NODE_ENV === 'production') {
      return settingsProd;
    }

    return settingsDev;
  }

  static get apiPaymentHost() {
    let url = '';
    if (process.env.NODE_ENV === 'production') {
      url = `https://payments.sportsheadz.com/prod/`;
    }
    if (process.env.NODE_ENV === 'development') {
      url = `https://payments.sportsheadz.com/stage/`;
    }
    if (process.env.NODE_ENV === 'none') {
      url = `https://localhost:5001/`;
    }
    if (window.location.hostname === 'dev.broadcast.sportsheadz.com') {
      url = `https://payments.sportsheadz.com/stage/`;
    }

    return url;
  }

  static get buySubscriptionUrl() {
    // const url = `${this.apiServerHost}api/stripe/subscription`
    const url = `${this.apiServerHost}api/payment/subscription`;
    return url;
  }

  static get buyTournamentSubscriptionUrl() {
    const url = `${this.apiServerHost}api/payment/subscription/tournament`;
    return url;
  }

  static get stripePublishableKey() {
    let key = '';
    if (process.env.NODE_ENV === 'production') {
      key = `pk_live_hMGDonAGKuDU6mDxpkKqCW3900Uqpiv2Ua`;
    }
    if (process.env.NODE_ENV === 'development') {
      key = `pk_test_vHpA9LSl9fXv3vCbiQsqc4y100mHM7EK55`;
    }
    if (process.env.NODE_ENV === 'none') {
      key = `pk_test_vHpA9LSl9fXv3vCbiQsqc4y100mHM7EK55`;
    }
    if (window.location.hostname === 'dev.broadcast.sportsheadz.com') {
      key = `pk_test_vHpA9LSl9fXv3vCbiQsqc4y100mHM7EK55`;
    }

    return key;
  }

  static get privacyPolicyUrl() {
    return 'https://www.sportsheadz.com/privacy-policy/';
  }

  static get allChannelsUrl() {
    return `${Config.apiServerHost}api/hockeysight/channels`;
  }

  static get updateChannelState() {
    return `${Config.apiServerHost}api/hockeysight/channel/state`;
  }

  static updateRecordingState(channelId) {
    return `${Config.apiServerHost}api/hockeysight/channel/${channelId}/recording`;
  }

  static updateStreamUrl(channelId, blackoutMode) {
    return `${Config.apiServerHost}api/hockeysight/channel/${channelId}?blackout=${blackoutMode}`;
  }

  static updateCameraState(cameraId, turnOn) {
    if (turnOn) {
      return `${Config.apiServerHost}api/camera/${cameraId}/start`;
    }
    return `${Config.apiServerHost}api/camera/${cameraId}/stop`;
  }

  static get allVenues() {
    return 'api/hockeysight/venues';
  }

  static get upcomingAndCurrentEvents() {
    return 'api/schedule/upcomingnew';
  }

  static get recordedEvents() {
    return 'api/schedule/recordednew';
  }

  static get allVods() {
    return 'api/hockeysight/vod';
  }

  static get vodClip() {
    return '/api/vod/clip';
  }

  static VodClipping(pkClipping) {
    return `/api/vod/clipping/${pkClipping}`;
  }

  static get vodHighlight() {
    return '/api/vod/highlight';
  }

  static get meHighlight() {
    return '/api/me/highlight';
  }

  static get getCamera() {
    return '/api/hockeysight/camera';
  }

  static get getEvent() {
    return '/api/hockeysight/event';
  }

  static get getTournaments() {
    return '/api/tournament';
  }

  static get getTournamentsWithEvents() {
    return '/api/tournament/tournaments-with-events';
  }

  static getTournamentEvents({ pkTournament }) {
    return `/api/tournament/${pkTournament}/eventsnew`;
  }

  static get getAssociations() {
    return '/api/association';
  }

  // TODO: pass in start and end date...
  static getVods({ pkStream, start, end }) {
    return `api/hockeysight/vod?pkStream=${pkStream}&start=${start}&end=${end}`;
  }

  static getVodDates({ pkStream }) {
    return `/api/hockeysight/vod-dates?pkStream=${pkStream}`;
  }

  static getVodQueue({ pkStream, startDateMS, endDateMS }) {
    return `api/hockeysight/vod-queue?pkStream=${pkStream}&start=${startDateMS}&end=${endDateMS}`;
  }

  static clipPageUrl(mp4Url) {
    return `${window.location.origin}/live-streams/clip?vid=${mp4Url}`;
  }

  static getPromoDetail(promocode) {
    return `${Config.apiServerHost}api/Payment/promotions?promo=${promocode}`;
  }

  static get redeemGroupPromo() {
    return `/api/payment/promotions/group/redeem`;
  }

  static get checkForRegisteredEmail() {
    return `/api/User/email-registered`;
  }
}
