import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import { Box } from './box';
import { Text } from './text';

const propTypes = {
  backgroundColor: PropTypes.any,
  image: PropTypes.any,
  imageHeight: PropTypes.number,
  imageStyle: PropTypes.object,
  invert: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string
};

const MarketingBanner = ({
  image,
  invert,
  imageHeight,
  title,
  text,
  backgroundColor,
  imageStyle
}) => {
  const screenNarrow = useMediaQuery('(max-width:767px)');
  return (
    <Box style={{ padding: '70px 45px', backgroundColor }}>
      <Box
        flexDirection={['column', 'column', 'row']}
        style={{
          margin: '0 auto',
          maxWidth: 1100,
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {image && invert && !screenNarrow ? (
          <img
            alt=""
            src={image}
            style={{
              height: '100%',
              maxHeight: imageHeight,
              maxWidth: '100%',
              ...imageStyle
            }}
          />
        ) : null}
        <Box
          paddingBottom={
            invert && !screenNarrow ? null : ['50px', '50px', '0px']
          }
          paddingLeft={invert ? ['0px', '0px', '50px'] : null}
          paddingRight={invert ? null : ['0px', '0px', '50px']}
          paddingTop={invert && !screenNarrow ? ['50px', '50px', '0px'] : null}
          style={{ flexDirection: 'column' }}
        >
          <Text
            fontSize={['26px', '40px', '50px']}
            style={{ color: '#fff', fontWeight: 'bold' }}
          >
            {title}
          </Text>
          <Text
            fontSize={['18px', '20px', '26px']}
            style={{
              color: '#fff',
              fontWeight: '400',
              marginTop: 25,
              lineHeight: 'normal'
            }}
          >
            {text}
          </Text>
        </Box>
        {image && (!invert || screenNarrow) ? (
          <img
            alt=""
            src={image}
            style={{
              height: '100%',
              maxHeight: imageHeight,
              maxWidth: '100%',
              ...imageStyle
            }}
          />
        ) : null}
      </Box>
    </Box>
  );
};

MarketingBanner.propTypes = propTypes;

export { MarketingBanner };
