import moment from 'moment';
import Config from '../config';
import { error } from './logging';

const chunk = (input, size) =>
  input.reduce(
    (arr, item, idx) =>
      idx % size === 0
        ? [...arr, [item]]
        : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]],
    []
  );

const timeout = ms => new Promise(res => setTimeout(res, ms));

const getInitialsFromName = name => {
  if (name == null || name.length === 0) {
    return '';
  }

  let avatarTitle = name[0];
  const familyNameIndex = name.indexOf(' ');

  if (familyNameIndex !== -1) {
    avatarTitle += name[familyNameIndex + 1];
  }

  return avatarTitle;
};

const formatDate = dotNetDate => {
  try {
    return moment(new Date(dotNetDate)).format('DD-MM-YYYY HH:mm:ss');
  } catch (ex) {
    error(`Error formatting date [${dotNetDate}] for user: ${ex}`);
  }
  return dotNetDate;
};

const formatDateNice = dotNetDate => {
  try {
    return moment(new Date(dotNetDate)).format('D MMM YYYY');
  } catch (ex) {
    error(`Error formatting date [${dotNetDate}] for user: ${ex}`);
  }
  return dotNetDate;
};

const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

const hasDisabledStream = v => {
  const isDisabled =
    v &&
    v.cameras &&
    v.cameras.length > 0 &&
    v.cameras[0].stream &&
    v.cameras[0].stream.disabled;
  return isDisabled;
};

const stripUtcDateFormatting = date =>
  `${date.charAt(date.length - 1) === 'Z' ? date.slice(0, -1) : date}`;

export {
  chunk,
  timeout,
  getInitialsFromName,
  formatDate,
  formatDateNice,
  getDateWithFormat,
  getCurrentTime,
  hasDisabledStream,
  stripUtcDateFormatting
};
