import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { Box } from '../elements';

const FormDialog = ({ title, children, onClose, onSubmit, isOpen }) => {
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          as="form"
          autoComplete="off"
          onSubmit={onSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {children}
          <DialogActions>
            <Button onClick={onClose} style={{ fontSize: '0.875rem' }}>
              Cancel
            </Button>
            <Button style={{ fontSize: '0.875rem' }} type="submit">
              Submit
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

FormDialog.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.string
};

export { FormDialog };
