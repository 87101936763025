import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { Box } from '../elements';

const Alert = ({ title, message, onCancel, onConfirm, isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Box>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: 'center' }}
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

Alert.propTypes = {
  message: PropTypes.node.isRequired,
  onCancel: PropTypes.node.isRequired,
  onConfirm: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  isVisible: PropTypes.bool
};

export { Alert };
