import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Divider, Checkbox } from 'semantic-ui-react';
import { Box, Input, Text } from '../../components/elements';
import { Message, Loading } from '../../components/compound';
import { useAuthService } from './auth.provider';

const SendAuthCode = ({ email, title, type, onSendCode, onCancel }) => {
  const { onCheckExistingUser, error, loading, clearErrors } = useAuthService();
  const [formState, setFormState] = useState({
    email,
    emailFound: email !== undefined,
    codeOption: 'email'
  });
  const emailRef = useRef();

  useEffect(() => {
    setFormState({
      email,
      emailFound: email !== undefined,
      codeOption: 'email'
    });
  }, []);

  useEffect(() => {
    clearErrors();
  }, [formState.emailFound]);

  useEffect(() => {
    if (!formState.emailFound && emailRef && emailRef.current) {
      emailRef.current.focus();
    }
  }, [formState.emailFound]);

  return (
    <Box flex={1} flexDirection="column">
      <Text
        fontSize={5}
        fontWeight="bold"
        style={{ padding: 16, lineHeight: 'normal' }}
      >
        {formState.emailFound ? title : 'Find Your Account'}
      </Text>

      <Divider style={{ margin: 0 }} />

      <Box
        alignItems="center"
        as="form"
        flex={1}
        flexDirection="column"
        onSubmit={e => {
          e.preventDefault();

          if (formState.emailFound) {
            onSendCode(formState.email);
          } else {
            setFormState({
              ...formState,
              emailFound: false
            });
            onCheckExistingUser({ email: formState.email }, () => {
              setFormState({
                ...formState,
                emailFound: true
              });
            });
          }
        }}
      >
        {formState.emailFound ? (
          <>
            {error && (
              <Box
                style={{ padding: '24px 16px', paddingBottom: 0 }}
                width="100%"
              >
                <Message
                  mt={5}
                  style={{ width: '100%', lineHeight: 'normal' }}
                  variant="error"
                >
                  <Text fontWeight="bold">{error}</Text>
                </Message>
              </Box>
            )}
            <Box style={{ padding: '24px 16px' }} width="100%">
              <Box flex={1} flexDirection="column">
                {type === 'password' ? (
                  <Text style={{ marginBottom: '24px' }}>
                    How do you want to get the code to reset your password?
                  </Text>
                ) : (
                  <Text style={{ marginBottom: '24px' }}>
                    How do you want to get the verification code to confirm your
                    account?
                  </Text>
                )}
                <Box flex={1} flexDirection="row" style={{ cursor: 'pointer' }}>
                  <Checkbox
                    checked={formState.codeOption === 'email'}
                    radio
                    style={{ margin: 'auto 0' }}
                  />
                  <Box flexDirection="column" style={{ marginLeft: 20 }}>
                    <Text>Send code via email</Text>
                    <Text fontSize="14px">{formState.email}</Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <>
            {error && (
              <Box
                style={{ padding: '24px 16px', paddingBottom: 0 }}
                width="100%"
              >
                <Message
                  mt={5}
                  style={{ width: '100%', lineHeight: 'normal' }}
                  variant="error"
                >
                  <Text fontWeight="bold" mb="2px">
                    No Account Found
                  </Text>
                  <Text>
                    Your search did not return any results. Please try again
                    with other information.
                  </Text>
                </Message>
              </Box>
            )}

            <Box
              flex={1}
              flexDirection="column"
              style={{ padding: '24px 16px' }}
              width="100%"
            >
              <Text style={{ width: '100%', marginBottom: '16px' }}>
                Please enter your email to search for your account.
              </Text>
              <Input
                ref={emailRef}
                containerProps={{
                  width: '100%',
                  height: '40px',
                  alignItems: 'start'
                }}
                name="Email"
                onChange={e =>
                  setFormState({
                    ...formState,
                    email: e.target.value,
                    emailFound: false
                  })
                }
                placeholder="Email"
                required
                style={{ borderRadius: '4px' }}
                type="email"
                value={formState.email ?? ''}
                variant="register"
              />
            </Box>
          </>
        )}
        <Divider style={{ width: '100%', margin: 0 }} />

        {loading ? (
          <Box style={{ padding: '24px 16px' }}>
            <Loading dotBackgroundColor="black" />
          </Box>
        ) : (
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="end"
            style={{ padding: '24px 16px' }}
            width="100%"
          >
            {formState.emailFound && (
              <Box flex={1} style={{ placeItems: 'center' }}>
                <Text
                  onClick={() =>
                    setFormState({
                      ...formState,
                      email: '',
                      emailFound: false
                    })
                  }
                  style={{ color: '#385898', cursor: 'pointer' }}
                >
                  Not you?
                </Text>
              </Box>
            )}

            <Box>
              <Button
                color="secondary"
                onClick={() => onCancel()}
                style={{
                  marginRight: 10,
                  textTransform: 'none'
                }}
                type="button"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: '#F3C20A',
                  color: 'white',
                  padding: '5px 15px',
                  textTransform: 'none'
                }}
                type="submit"
              >
                {formState.emailFound ? 'Continue' : 'Search'}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

SendAuthCode.propTypes = {
  email: PropTypes.string,
  onCancel: PropTypes.func,
  onSendCode: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string
};

export { SendAuthCode };
