import { Button, useMediaQuery } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { navigate } from '@reach/router';
import React, { useState } from 'react';
import { Box, Text, MarketingBanner } from '../../components/elements';

import hero from '../../images/sportsheadz_site_hero_v2.jpg';
import mktWebStream from '../../images/Marketing-Web-Stream.png';
import mktStreamFeatures from '../../images/Marketing-Stream-Features.png';
import mktHero from '../../images/Marketing-Hero.jpg';
import mktVodFeatures from '../../images/Marketing-Vod-Features.png';

import { useAuthService } from '../auth';
import { theme } from '../../styles';
import AuthModal from '../auth/AuthModal';

const Home = () => {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const screenNarrow = useMediaQuery('(max-width:767px)');
  const { isAuthenticated } = useAuthService();

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        flex: 1,
        backgroundColor: '#fff',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 40,
        padding: 10
      }}
    >
      <Text style={{ textAlign: 'center' }}>
        Live Streaming services are now provided by LiveBarn.
      </Text>
      <Text style={{ textAlign: 'center' }}>
        Please visit <a href="https://livebarn.com">https://livebarn.com</a>
      </Text>
      <Text style={{ textAlign: 'center' }}>
        Any existing subscription renewals to SportsHeadz Streaming have already
        been cancelled. Thank you for your patronage.
      </Text>
    </Box>
  );
};

export { Home };
