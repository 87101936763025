import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuthService } from './auth.provider';
import { SendAuthCode } from './SendAuthCode';
import { Box } from '../../components/elements';

const ForgotPassword = ({ changeView, fullScreen, navigate }) => {
  const {
    onReset,
    onForgotPassword,
    clearErrors,
    isWaitingConfirmSignUp
  } = useAuthService();

  useEffect(() => {
    onReset();
  }, []);

  useEffect(() => {
    if (isWaitingConfirmSignUp) {
      clearErrors();
      if (fullScreen) {
        navigate(`/confirm`);
      } else {
        changeView('confirm-account');
      }
    }
  }, [isWaitingConfirmSignUp]);

  const viewSwitch = view => {
    clearErrors();
    if (fullScreen) {
      navigate(`/${view}`);
    } else {
      changeView(view);
    }
  };

  return (
    <Box
      flexDirection="column"
      maxWidth="435px"
      style={{
        margin: fullScreen ? '1.25rem auto' : null,
        borderRadius: fullScreen ? 10 : null,
        backgroundColor: fullScreen ? '#FFF' : null,
        boxShadow: fullScreen ? 2 : 0
      }}
      width="100%"
    >
      <SendAuthCode
        fullScreen={fullScreen}
        onCancel={() => {
          clearErrors();
          viewSwitch('login');
        }}
        onSendCode={email => {
          onForgotPassword(email, () => viewSwitch('reset-password'));
        }}
        title="Reset Your Password"
        type="password"
      />
    </Box>
  );
};

ForgotPassword.propTypes = {
  changeView: PropTypes.func,
  fullScreen: PropTypes.bool,
  navigate: PropTypes.func
};

export { ForgotPassword };
