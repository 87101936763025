import styled from 'styled-components';
import { Box } from '../elements/box';

const Skeleton = styled(Box)`
  display: inline-block;
  height: ${({ height }) => height || '70%'};
  border-radius: ${({ theme }) => theme.radii[2]};
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

export { Skeleton };
