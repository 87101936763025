import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

const ContentModal = ({
  isOpen,
  onClose,
  size,
  children,
  modalStyle,
  modalProps
}) => {
  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      size={size ?? 'mini'}
      style={{
        position: 'relative',
        height: 'unset',
        ...modalStyle
      }}
      {...modalProps}
    >
      {children}
    </Modal>
  );
};

ContentModal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  modalProps: PropTypes.object,
  modalStyle: PropTypes.object,
  onClose: PropTypes.func,
  size: PropTypes.string
};

export default ContentModal;
