import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Divider, Image } from 'semantic-ui-react';
import { Box, Input, Text } from '../../components/elements';
import { Loading, Message } from '../../components/compound';
import { useAuthService } from './auth.provider';
import Config from '../../config';
import { LengthRequirement } from '../../utils/password';
import logo from '../../images/sportsheads-logo.png';

const CreateAccount = ({ changeView, fullScreen, navigate }) => {
  const {
    onReset,
    onCreateAccount,
    error,
    clearErrors,
    loading,
    isWaitingConfirmSignUp
  } = useAuthService();
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    error: ''
  });
  const emailRef = useRef();
  useEffect(() => {
    onReset();
    if (emailRef && emailRef.current) {
      emailRef.current.focus();
    }
    setFormState({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      error: ''
    });
  }, []);

  useEffect(() => {
    if (isWaitingConfirmSignUp) {
      clearErrors();
      if (fullScreen) {
        navigate(`/confirm`);
      } else {
        changeView('confirm-account');
      }
    }
  }, [isWaitingConfirmSignUp]);

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
      error: ''
    });
  };

  return (
    <Box
      as="form"
      flexDirection="column"
      maxWidth="435px"
      onSubmit={e => {
        e.preventDefault();
        if (formState.confirmPassword !== formState.password) {
          setFormState({
            ...formState,
            error: 'Passwords must match.'
          });
        } else if (formState.confirmPassword.length < LengthRequirement) {
          setFormState({
            ...formState,
            error: `Password must be at least ${LengthRequirement} characters long`
          });
        } else {
          onCreateAccount(formState);
        }
      }}
      style={{
        margin: fullScreen ? '1.25rem auto' : null,
        borderRadius: fullScreen ? 10 : null,
        backgroundColor: fullScreen ? '#FFF' : null,
        boxShadow: fullScreen ? 2 : 0
      }}
      width="100%"
    >
      <Box
        flex={1}
        flexDirection="center"
        justifyContent="space-between"
        style={{
          padding: '24px 16px'
        }}
      >
        <Box flexDirection="column">
          <Text
            fontSize={32}
            fontWeight={600}
            height={38}
            style={{
              padding: 0,
              marginBottom: 5
            }}
          >
            Join Now
          </Text>
          <Text color="#606770" fontSize={15} fontWeight="normal">
            Never miss another game.
          </Text>
        </Box>
        <Image
          src={logo}
          style={{ margin: 'auto 0px', width: 65, height: 65 }}
        />
      </Box>
      <Divider style={{ margin: 0 }} />
      {(error || formState.error) && (
        <Box style={{ padding: '24px 16px', paddingBottom: 0 }} width="100%">
          <Message
            mt={5}
            style={{ width: '100%', lineHeight: 'normal' }}
            variant="error"
          >
            <Text fontWeight="bold">{error ?? formState.error}</Text>
          </Message>
        </Box>
      )}
      <Box
        flexDirection="column"
        style={{
          padding: '24px 16px'
        }}
      >
        <Box>
          <Input
            containerProps={{
              width: '100%',
              height: '40px',
              marginRight: 2
            }}
            name="firstName"
            onChange={handleChange}
            placeholder="First name"
            required
            style={{ borderRadius: '4px' }}
            value={formState.firstName ?? ''}
            variant="register"
          />
          <Input
            containerProps={{
              width: '100%',
              height: '40px',
              marginLeft: 2
            }}
            name="lastName"
            onChange={handleChange}
            placeholder="Last name"
            required
            style={{ borderRadius: '4px' }}
            value={formState.lastName ?? ''}
            variant="register"
          />
        </Box>
        <Input
          containerProps={{
            mt: 3,
            width: '100%',
            height: '40px'
          }}
          name="email"
          onChange={handleChange}
          placeholder="Email"
          required
          style={{ borderRadius: '4px' }}
          value={formState.email ?? ''}
          variant="register"
        />
        <Input
          containerProps={{
            mt: 3,
            width: '100%',
            height: '40px'
          }}
          name="password"
          onChange={handleChange}
          placeholder="Password"
          required
          style={{ borderRadius: '4px' }}
          type="password"
          value={formState.password ?? ''}
          variant="register"
        />
        <Input
          containerProps={{
            mt: 3,
            width: '100%',
            height: '40px'
          }}
          name="confirmPassword"
          onChange={handleChange}
          placeholder="Confirm Password"
          required
          style={{ borderRadius: '4px' }}
          type="password"
          value={formState.confirmPassword ?? ''}
          variant="register"
        />
      </Box>

      {loading ? (
        <Box
          flex={1}
          justifyContent="center"
          style={{ padding: '24px 16px', paddingTop: 0 }}
        >
          <Loading dotBackgroundColor="black" />
        </Box>
      ) : (
        <>
          <Box
            flexDirection="column"
            style={{
              padding: 16,
              paddingBottom: 0,
              paddingTop: 0,
              textAlign: 'center'
            }}
          >
            <Text style={{ fontSize: 11, marginTop: 5, color: '#777' }}>
              By clicking Join Now, you agree to our{' '}
              <span
                onClick={() => window.open(Config.privacyPolicyUrl, '_blank')}
                role="link"
                style={{ color: '#385898', cursor: 'pointer' }}
              >
                Privacy Policy
              </span>
              .
            </Text>
          </Box>
          <Box flex={1} style={{ padding: '24px 16px' }}>
            <Box
              flex={1}
              flexDirection="row"
              justifyContent="space-between"
              style={{ placeItems: 'center' }}
            >
              <Text
                onClick={() => {
                  clearErrors();
                  fullScreen ? navigate(`/login`) : changeView('login');
                }}
                style={{ color: '#385898', cursor: 'pointer' }}
              >
                Already a member?
              </Text>
              <Button
                size="small"
                style={{
                  width: 200,
                  backgroundColor: '#F3C20A',
                  color: 'white',
                  textTransform: 'none'
                }}
                type="submit"
              >
                Join Now
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

CreateAccount.propTypes = {
  changeView: PropTypes.func,
  fullScreen: PropTypes.bool,
  navigate: PropTypes.func
};

export { CreateAccount };
