import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from './box';

const propTypes = {
  onToggle: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

const ToggleBox = styled.fieldset`
  display: flex;
  border-radius: 5px;
  border: 1px solid #c7c7c7;
  overflow: hidden;
  background: #222;

  label {
    display: flex;
  }
`;

const ToggleLabel = styled.label`
  position: relative;
  cursor: pointer;
  span {
    transition: all 0.2s;
    border-radius: 3px;
    padding: 0.1875rem 0.3125rem;
    border-color: #222;
    background: #222;
    color: #ddd;
  }

  &:first-child {
    /* margin-right: 0.3125rem; */
  }

  input {
    position: absolute;
    opacity: 0;

    &:focus + span {
      /* sbackground: blue; */
    }

    &:checked + span {
      border-color: #ddd;
      background: #ddd;
      color: #222;
    }
  }
`;

const Toggle = ({ value, onToggle }) => (
  <ToggleBox>
    <Box justifyContent="space-between">
      <ToggleLabel htmlFor="am">
        <input
          checked={value === 'am'}
          id="am"
          onChange={() => onToggle('am')}
          type="radio"
          value="am"
        />
        <span>AM</span>
      </ToggleLabel>
      <ToggleLabel htmlFor="pm">
        <input
          checked={value === 'pm'}
          id="pm"
          onChange={() => onToggle('pm')}
          type="radio"
          value="pm"
        />
        <span>PM</span>
      </ToggleLabel>
    </Box>
  </ToggleBox>
);

Toggle.propTypes = propTypes;

export { Toggle };
