import React from 'react';
import PropTypes from 'prop-types';
import Background from '../../images/bgx.png';
import { useAuthService } from '../../features/auth';
import { Box } from './box';

const PrimaryBackground = ({ children, ...props }) => {
  const { isDarkMode } = useAuthService();

  return (
    <Box
      alignItems="center"
      flexDirection="column"
      style={{
        flex: 1,
        background: isDarkMode ? `url(${Background})` : null,
        backgroundColor: isDarkMode ? 'black' : '#F0F2F5',
        backgroundRepeat: isDarkMode ? 'repeat-x' : null,
        backgroundPosition: isDarkMode ? 'top left' : null,
        backgroundSize: 'cover'
      }}
      width="100%"
      {...props}
    >
      {children}
    </Box>
  );
};

PrimaryBackground.propTypes = {
  children: PropTypes.node
};

export { PrimaryBackground };
