import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Divider } from 'semantic-ui-react';
import { useSnackbar } from 'notistack';
import { Box, Input, Text } from '../../components/elements';
import { Message, Loading } from '../../components/compound';
import { useAuthService } from './auth.provider';
import { LengthRequirement } from '../../utils/password';

const ResetPassword = ({ changeView, fullScreen, navigate, closeModal }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    onResetPassword,
    onReset,
    error,
    clearErrors,
    loading,
    passwordResetSuccessful,
    email
  } = useAuthService();
  const [formState, setFormState] = useState({
    token: '',
    email,
    newPassword: '',
    passwordConfirmation: '',
    error: ''
  });
  const tokenRef = useRef();

  useEffect(() => {
    onReset();
    if (tokenRef && tokenRef.current) {
      tokenRef.current.focus();
    }
    setFormState({
      token: '',
      email,
      newPassword: '',
      passwordConfirmation: '',
      error: ''
    });
  }, []);

  useEffect(() => {
    if (passwordResetSuccessful) {
      enqueueSnackbar('Password was successfully reset.', {
        variant: 'success'
      });

      if (fullScreen) {
        navigate(`/login`);
      } else {
        changeView('login');
      }
    }
  }, [passwordResetSuccessful]);

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  const viewSwitch = view => {
    clearErrors();

    if (fullScreen) {
      navigate(`/${view}`);
    } else {
      changeView(view);
    }
  };

  return (
    <Box
      flexDirection="column"
      maxWidth="435px"
      style={{
        margin: fullScreen ? '1.25rem auto' : null,
        borderRadius: fullScreen ? 10 : null,
        backgroundColor: fullScreen ? '#FFF' : null,
        boxShadow: fullScreen ? 2 : 0
      }}
      width="100%"
    >
      <Text
        fontSize={5}
        fontWeight="bold"
        style={{ padding: 16, lineHeight: 'normal' }}
      >
        Reset Your Password
      </Text>

      <Divider style={{ margin: 0 }} />

      <Box
        alignItems="center"
        as="form"
        flex={1}
        flexDirection="column"
        onSubmit={e => {
          e.preventDefault();
          if (formState.newPassword !== formState.passwordConfirmation) {
            setFormState({
              ...formState,
              error: 'Password and Confirm Password do not match'
            });
          } else if (formState.newPassword.length < LengthRequirement) {
            setFormState({
              ...formState,
              error: `Password must be at least ${LengthRequirement} characters long`
            });
          } else {
            setFormState({
              ...formState,
              error: ''
            });
            onResetPassword(formState);
          }
        }}
      >
        {(error || formState.error) && (
          <Box style={{ padding: 20, paddingBottom: 0 }} width="100%">
            <Message
              mt={5}
              style={{ width: '100%', lineHeight: 'normal' }}
              variant="error"
            >
              <Text fontWeight="bold">{error ?? formState.error}</Text>
            </Message>
          </Box>
        )}
        <Box
          flex={1}
          flexDirection="column"
          style={{ padding: '24px 16px' }}
          width="100%"
        >
          <Input
            ref={tokenRef}
            containerProps={{
              width: '100%',
              height: '40px',
              alignItems: 'start'
            }}
            name="token"
            onChange={handleChange}
            placeholder="Verification Code"
            required
            style={{ borderRadius: '4px' }}
            type="text"
            value={formState.token ?? ''}
            variant="register"
          />
          <Input
            containerProps={{
              width: '100%',
              height: '40px',
              alignItems: 'start',
              marginTop: 24
            }}
            name="newPassword"
            onChange={handleChange}
            placeholder="New Password"
            required
            style={{ borderRadius: '4px' }}
            type="password"
            value={formState.newPassword ?? ''}
            variant="register"
          />
          <Input
            containerProps={{
              width: '100%',
              height: '40px',
              alignItems: 'start',
              marginTop: 24
            }}
            name="passwordConfirmation"
            onChange={handleChange}
            placeholder="Confirm Password"
            required
            style={{ borderRadius: '4px' }}
            type="password"
            value={formState.passwordConfirmation ?? ''}
            variant="register"
          />
        </Box>
        <Divider style={{ width: '100%', margin: 0 }} />
        {loading ? (
          <Box style={{ padding: '24px 16px' }}>
            <Loading dotBackgroundColor="black" />
          </Box>
        ) : (
          <Box
            flex={1}
            justifyContent="end"
            style={{ padding: '24px 16px' }}
            width="100%"
          >
            <Box>
              <Button
                color="secondary"
                onClick={() => viewSwitch('login')}
                style={{ marginRight: 10, textTransform: 'none' }}
                type="button"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: '#F3C20A',
                  color: 'white',
                  padding: '5px 15px',
                  textTransform: 'none'
                }}
                type="submit"
              >
                Continue
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

ResetPassword.propTypes = {
  changeView: PropTypes.func,
  closeModal: PropTypes.func,
  fullScreen: PropTypes.bool,
  navigate: PropTypes.func
};

export { ResetPassword };
