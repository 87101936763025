import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Divider } from 'semantic-ui-react';
import { useSnackbar } from 'notistack';
import { Box, Input, Text } from '../../components/elements';
import { Loading, Message } from '../../components/compound';
import { useAuthService } from './auth.provider';
import { SendAuthCode } from './SendAuthCode';

const ConfirmAccount = ({ changeView, closeModal, fullScreen, navigate }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    onResendVerificationCode,
    onConfirmAccount,
    clearErrors,
    error,
    loading,
    isConfirmed,
    email,
    password,
    onLogin
  } = useAuthService();
  const [formState, setFormState] = useState({
    email,
    password,
    confirmationCode: '',
    error: '',
    showResendCode: false
  });
  const usernameRef = useRef();

  useEffect(() => {
    clearErrors();
    if (usernameRef && usernameRef.current) {
      usernameRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!email) {
      if (fullScreen) {
        navigate(`/login`);
      } else {
        changeView('login');
      }
    }
  }, [email]);

  useEffect(() => {
    if (isConfirmed) {
      enqueueSnackbar('Your account is now confirmed. Thank you.', {
        variant: 'success'
      });

      onLogin(formState, () => {
        if (fullScreen) {
          navigate(`/`);
        } else {
          closeModal();
        }
      });
    }
  }, [isConfirmed]);

  useEffect(() => {
    if (isConfirmed && error) {
      if (fullScreen) {
        navigate(`/login`);
      } else {
        changeView('login');
      }
    }
  }, [error]);

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
      error: ''
    });
  };

  return (
    <Box
      flexDirection="column"
      maxWidth="435px"
      style={{
        margin: fullScreen ? '1.25rem auto' : null,
        borderRadius: fullScreen ? 10 : null,
        backgroundColor: fullScreen ? '#FFF' : null,
        boxShadow: fullScreen ? 2 : 0
      }}
      width="100%"
    >
      {formState.showResendCode ? (
        <SendAuthCode
          email={formState.email}
          fullScreen={fullScreen}
          onCancel={() => {
            clearErrors();
            setFormState({
              ...formState,
              showResendCode: false
            });
          }}
          onSendCode={userEmail => {
            onResendVerificationCode({ email: userEmail }, () =>
              setFormState({
                ...formState,
                showResendCode: false
              })
            );
          }}
          title="Confirm Your Account"
        />
      ) : (
        <Box
          as="form"
          flexDirection="column"
          onSubmit={e => {
            e.preventDefault();
            onConfirmAccount(formState);
          }}
          width="100%"
        >
          <Box
            flexDirection="column"
            style={{
              padding: '24px 16px'
            }}
          >
            <Text fontSize={5} fontWeight="bold">
              Confirm Your Account
            </Text>
            <Text color="#606770" fontSize={15} fontWeight="normal">
              You're almost there!
            </Text>
          </Box>

          <Divider style={{ margin: 0 }} />

          {(error || formState.error) && (
            <Box
              style={{ padding: '24px 16px', paddingBottom: 0 }}
              width="100%"
            >
              <Message
                mt={5}
                style={{ width: '100%', lineHeight: 'normal' }}
                variant="error"
              >
                <Text fontWeight="bold">{error ?? formState.error}</Text>
              </Message>
            </Box>
          )}

          <Box
            flex={1}
            flexDirection="column"
            style={{
              padding: '24px 16px'
            }}
          >
            <Text>
              We've sent a verification email to you with a link and a code.
              Either click the link or enter the code below - only one is
              necessary!
            </Text>

            <Box flexDirection="column" style={{ paddingTop: 24 }}>
              <Input
                containerProps={{
                  width: '100%',
                  height: '40px'
                }}
                name="confirmationCode"
                onChange={handleChange}
                placeholder="Confirmation Code"
                required
                style={{ borderRadius: '4px' }}
                value={formState.confirmationCode ?? ''}
                variant="register"
              />
            </Box>
          </Box>

          <Divider style={{ margin: 0 }} />

          {loading ? (
            <Box style={{ alignSelf: 'center', padding: '24px 16px' }}>
              <Loading dotBackgroundColor="black" />
            </Box>
          ) : (
            <Box
              flex={1}
              justifyContent="space-between"
              style={{
                padding: '24px 16px',
                placeItems: 'center'
              }}
            >
              <Text
                onClick={() =>
                  setFormState({
                    ...formState,
                    showResendCode: true
                  })
                }
                style={{ color: '#385898', cursor: 'pointer' }}
              >
                Didn't get a code?
              </Text>
              <Button
                style={{
                  backgroundColor: '#F3C20A',
                  color: 'white',
                  padding: '5px 30px',
                  textTransform: 'none'
                }}
                type="submit"
              >
                Confirm
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

ConfirmAccount.propTypes = {
  changeView: PropTypes.func,
  closeModal: PropTypes.func,
  fullScreen: PropTypes.bool,
  navigate: PropTypes.func
};

export { ConfirmAccount };
