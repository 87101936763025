import React from 'react';
import ReactCalendar from 'react-calendar/dist/entry.nostyle';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { PropTypes } from 'prop-types';

const BaseCalendar = styled(ReactCalendar)`
  border-width: thin;
  color: black;
  /* width: 350px; */
  max-width: 100%;
  background: white;
  /* border: 1px solid rgb(160, 160, 150); */
  font-family: inherit;
  line-height: 1.125em;

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  button {
    margin: 0;
    border: 0;
    outline: none;

    &:enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
// DatePicker
// const reactDatePicker = 'react-date-picker'
// const disabled = `${reactDatePicker}--disabled`
// const wrapper = `${reactDatePicker}__wrapper`
// const inputGroup = `${reactDatePicker}__inputGroup`
// const button = `${reactDatePicker}__button`
// const calendar = `${reactDatePicker}__calendar`

// Calendar
const reactCalendar = 'react-calendar';
const doubleView = `${reactCalendar}--doubleView`;
const navigation = `${reactCalendar}__navigation`;
const monthView = `${reactCalendar}__month-view`;

const yearView = `${reactCalendar}__year-view`;
const decadeView = `${reactCalendar}__decade-view`;
const centuryView = `${reactCalendar}__century-view`;

const tile = `${reactCalendar}__tile`;

const selectRange = `${reactCalendar}--selectRange`;

const hasActiveColor = lighten(0.3, 'rgb(0, 110, 220)');
const activeColor = 'rgb(0, 110, 220)';

const darkBase = css`
  background: #121212;
  color: #ddd;
`;

const MyCalendar = styled(BaseCalendar).attrs({})`
  ${({ darkMode }) => darkMode && darkBase}

  button {
    ${({ darkMode }) => darkMode && `color: #ddd;`}
  }

  .${doubleView} {
    width: 700px;

    .react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;

      > * {
        width: 50%;
        margin: 0.5em;
      }
    }
  }

  .${navigation} {
    height: 44px;
    margin-bottom: 1em;

    button {
      min-width: 44px;
      background: none;

      &:enabled {
        &:hover,
        &:focus {
          background-color: rgb(230, 230, 230);
          ${({ darkMode }) => darkMode && 'color: black;'}
        }
      }

      &[disabled] {
        background-color: rgb(240, 240, 240);
      }
    }
  }

  .${monthView} {
    &__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;

      &__weekday {
        padding: 0.5em;
      }
    }

    &__weekNumbers {
      font-weight: bold;

      .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75em;
        padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
      }
    }

    &__days {
      &__day {
        &--weekend {
          color: rgb(209, 0, 0);
        }

        &--neighboringMonth {
          color: rgb(117, 117, 117);
        }
      }
    }
  }

  .${yearView}, .${decadeView}, .${centuryView} {
    .react-calendar__tile {
      padding: 2em 0.5em;
    }
  }

  .tournament {
    color: rgb(0, 255, 255)
  }

  .${tile} {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
    border-radius: 3px;

    &:disabled {
      /* background-color: ${({ darkMode }) => darkMode && 'color: black;'}; */
      color: #555;
    }

    &:enabled {
      &:hover,
      &:focus {
        background-color: rgb(230, 230, 230);
        color: black;
        ${({ darkMode }) => darkMode && 'color: black;'};
      }
    }

    &--hasActive {
      background: ${hasActiveColor};

      &:enabled {
        &:hover,
        &:focus {
          background: ${lighten(0.1, `${hasActiveColor}`)};
        }
      }
    }

    &--active {
      background: ${activeColor};
      color: white;

      &:enabled {
        &:hover,
        &:focus {
          background: ${lighten(0.1, `${activeColor}`)};
        }
      }
    }
  }

  .${selectRange} {
    .react-calendar__tile {
      &--hover {
        background-color: rgb(230, 230, 230);
      }
    }
  }
`;

const defaultProps = {
  darkMode: false,
  value: new Date()
};
const propTypes = {
  darkMode: PropTypes.bool,
  value: PropTypes.instanceOf(Date)
};

const Calendar = ({ darkMode, value, ...rest }) => (
  <MyCalendar darkMode={darkMode} value={value} {...rest} />
);

Calendar.propTypes = propTypes;
Calendar.defaultProps = defaultProps;

export { Calendar };
